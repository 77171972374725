import React from "react"
import styled from "styled-components"
import { lighten } from 'polished';

interface IProps {
  fillColor: string;
  defaultColor: string;
  progress: number;
  title: string;
}

const ScoreProgressBar: React.FC<IProps> = ({
  fillColor,
  defaultColor,
  progress,
  title,
}) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <OuterProgress backgroundColor={defaultColor}>
        <InnerProgress
          backgroundColor={fillColor}
          progress={progress}
        ></InnerProgress>
      </OuterProgress>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 130px;
`

const Title = styled.div`
  /* Add your text styles and colors here */
  margin-bottom: 4px;
  font-size: 14px;
`

const OuterProgress = styled.div<{ backgroundColor: string }>`
  position: relative;
  width: 100%;
  height: 4px;
  border-radius: 8px;
  background-color: ${({ backgroundColor }) => lighten(0.3, backgroundColor)}; // Lighten the default color
`

const InnerProgress = styled.div<{ backgroundColor: string; progress: number }>`
  position: absolute;
  height: 4px;
  border-radius: 8px;
  background-color: ${({ backgroundColor }) => backgroundColor}; 
  width: ${({ progress }) => progress}%;
`

export default ScoreProgressBar;