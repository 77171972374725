import { useAuth0 } from "@auth0/auth0-react"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { VALIDATE } from "../../../constants/api"
import { languages } from "../../../models/languages"
import colors from "../../../styles/colors"
import { applyTextStyle } from "../../../styles/typography"
import Dropdown from "../../forms/controls/Dropdown"
// import Switch from "../../forms/controls/Switch"
import Icon from "../../icon/Icon"
// import TopicButtonsList from "../../lists/TopicButtonsList"
import ToolTip from "../../ToolTip"
import { HighlightWithinTextarea } from "react-highlight-within-textarea"
import copyTextToClipboard from "../../../helpers/copyTextToClipboard.helper"
import Link from "../../actions/Link"
import Input from "../../forms/controls/Input"
import { Color } from "../../../models/colors"
import Popup from "../../Popup"
import { useToken } from "../../../context/UseToken"
import IntentTitle from "../../IntentTitle"

interface IHighlight {
  highlight: string
  component: React.FC
  // use className to pass custom props to tooltip
  className: { category: MessageCategory; withBackground: boolean }
}

type ResponseType = "HUMAN SAYS" | "ASSISTANT SAYS"

interface IFields {
  value: string
  response: ResponseType
  placeholder: string
}

// const topics: Topic[] = ["Voice", "Chat", "Multimodal", "Metaverse"]

interface IProps {
  className?: string
  onValidate: (validateResponse?: ValidateResponse) => void
  openMessages: boolean[]
}

const ComplexTextarea: React.FC<IProps> = ({
  className,
  onValidate,
  openMessages,
}) => {
  const [fields, setFields] = useState<IFields[]>([
    { value: "", response: "HUMAN SAYS", placeholder: "Past your text here" },
    {
      value: "",
      response: "ASSISTANT SAYS",
      placeholder: "Paste your text here",
    },
  ])
  const [language, setLanguage] = useState<Language>("EN")
  const [token, setToken] = useState<string>("")

  const { getToken } = useToken()

  const { isAuthenticated } = useAuth0()
  const [highlight, setHighlight] = useState<IHighlight[]>([])
  const [title, setTitle] = useState<string>("")
  const [isTitleInputOpen, setIsTitleInputOpen] = useState<boolean>(false)
  const [lastUsedArea, setLastUsedArea] = useState<number>()
  const [lastString, setlastString] = useState<string>()
  const [showCopyPopup, setShowCopyPopup] = useState<number>()
  const [isAddResponsePopupOpen, setIsAddResponsePopupOpen] =
    useState<boolean>(false)
  const [suggestions, setSuggestions] = useState<ValidateResponse>()

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     const getToken = async () => {
  //       setToken(await getAccessTokenSilently())
  //     }
  //     getToken()
  //   }
  // }, [isAuthenticated])

  useEffect(() => {
    if (lastUsedArea !== undefined) {
      const delayDebounceFn = setTimeout(() => {
        if (
          fields[lastUsedArea]
          // && fields[lastUsedArea].response === "ASSISTENT SAYS"
        ) {
          handleSubmit(lastUsedArea)
        }
      }, 1000)

      return () => clearTimeout(delayDebounceFn)
    }
  }, [fields])

  useEffect(() => {
    // Todo: Re-enable this
    return
    if (suggestions) {
      const highlightOptions: IHighlight[] = []
      let messageIndex = 0
      for (const message of suggestions.messages) {
        if (!message.origin.length) {
          messageIndex++
          continue
        }
        for (const origin of message.origin) {
          highlightOptions.push({
            highlight: origin,
            component: ToolTip,
            // unable to pass custom props to Tooltip so use className
            className: {
              category: message.category,
              withBackground: openMessages[messageIndex],
            },
          })
        }
        messageIndex++
      }

      setHighlight(highlightOptions)
    }
  }, [openMessages])

  const countWords = (str: string) => {
    if (!str) return 0
    str = str.replace(/(^\s*)|(\s*$)/gi, "")
    str = str.replace(/[ ]{2,}/gi, " ")
    str = str.replace(/\n /, "\n")
    return str.split(" ").length
  }

  const onChange = (textInTextArea: string = "", index: number) => {
    if (fields[index].value !== textInTextArea) {
      fields[index].value = textInTextArea
      setFields([...fields])
      // if (fields[index].response === "ASSISTENT SAYS") {
      setLastUsedArea(index)
      if (highlight.length) {
        setHighlight([])
      }
      // }
    }
  }

  const reset = () => {
    setFields([
      { value: "", response: "HUMAN SAYS", placeholder: "Past your text here" },
      {
        value: "",
        response: "ASSISTANT SAYS",
        placeholder: "Past your text here",
      },
    ])
    setHighlight([])
    setLastUsedArea(undefined)
    onValidate(undefined)
    setTitle("")
  }

  const addResponse = (responseType: ResponseType) => {
    setIsAddResponsePopupOpen(false)
    setLastUsedArea(undefined)
    setFields([
      ...fields,
      { value: "", response: responseType, placeholder: "Past your text here" },
    ])
  }

  const divide = (index: number) => {
    const sentences = fields[index].value
      .replace(/([.?!])\s*(?=[A-Z])/g, "$1|")
      .split("|")
      .filter(sentence => !!sentence)
      .map(sentence => sentence.trim())
    const middleIndex = Math.ceil(sentences.length / 2)

    const firstHalf = sentences.splice(0, middleIndex)
    const secondHalf = sentences.splice(-middleIndex)
    fields[index].value = firstHalf.join(" ")
    fields.splice(index + 1, 0, {
      response: "ASSISTANT SAYS",
      value: secondHalf.join(" "),
      placeholder: "Past your text here",
    })
    setFields([...fields])
  }

  const handleSubmit = async (index: number) => {
    if (lastString === fields[index].value) return

    setlastString(fields[index].value)
    try {
      const body = {
        text: fields[index].value,
        language: language,
      }
      const token = await getToken()
      const request = await fetch(
        `${process.env.GATSBY_API_URL}/v1/suggestions`,
        {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      const responseJson = await request.json()
      console.log("Response JSON: ", responseJson)
      onValidate(responseJson)
      setSuggestions(responseJson)

      // fields[index + 1].placeholder = responseJson.gpt3
      setFields([...fields])

      return
      // Todo: Re-enable this
      const highlightOptions: IHighlight[] = []
      for (const message of responseJson.messages) {
        if (!message.origin.length) {
          continue
        }
        for (const origin of message.origin) {
          highlightOptions.push({
            highlight: origin,
            component: ToolTip,
            // unable to pass custom props to Tooltip so use className
            className: { category: message.category, withBackground: false },
          })
        }
      }
      setHighlight(highlightOptions)
    } catch (error: any) {
      console.log("Error while handleSubmit:", error)
    }
  }

  return (
    <Wrapper className={className}>
      {/* <StyledTopicButtonsList
        selectedTopic={topic}
        onSelectTopic={topic => setTopic(topic)}
        topics={topics}
      ></StyledTopicButtonsList> */}
      <IntentTitle
        title={title}
        setTitle={setTitle}
        language={language}
        setLanguage={setLanguage}
      ></IntentTitle>

      {fields.map((field, index) => (
        <div key={index}>
          <VerticalSeparater />
          <TextAreaWrapper isLastUsedArea={lastUsedArea === index}>
            {/* TODO change icon to Close only */}
            {index !== 0 && index !== 1 && (
              <StyledCloseAreaIcon
                type="CloseOutline"
                size={24}
                color={colors.grey4}
                onClick={() => {
                  setLastUsedArea(undefined)
                  setFields(fields.filter((_, i) => i !== index))
                }}
              />
            )}
            <ResponseText
              color={field.response === "HUMAN SAYS" ? "green2" : "purple1"}
            >
              {field.response}
            </ResponseText>
            <HighlightWithinTextarea
              placeholder={field.placeholder}
              value={field.value}
              onChange={(text: string) => onChange(text, index)}
              highlight={lastUsedArea === index ? highlight : []}
            ></HighlightWithinTextarea>
            <TextAreaBottomWrapper>
              <TextAreaBottomHalfWrapper>
                {/* <FormatOptions>
            <Icon type="More" color={colors.grey2} size={24} />
          </FormatOptions> */}
                <TextAreaBottomText marginRight={15}>
                  {countWords(field.value)} Words
                </TextAreaBottomText>
                <VerticalLine />
                <TextAreaBottomText>
                  {field.value.length} Characters
                </TextAreaBottomText>
              </TextAreaBottomHalfWrapper>
              <TextAreaBottomHalfWrapper>
                {field.response === "ASSISTANT SAYS" && (
                  <SplitScreenLink
                    iconType="SplitScreen"
                    caption="Divide"
                    onClick={() => divide(index)}
                  />
                )}
                <Link
                  iconType="ContentCopy"
                  caption="Copy"
                  onClick={() => {
                    copyTextToClipboard(field.value)
                    setShowCopyPopup(index)
                    setTimeout(() => {
                      setShowCopyPopup(undefined)
                    }, 1300)
                  }}
                />
                {showCopyPopup === index && (
                  <CopyToClipboardPopup>Copied!</CopyToClipboardPopup>
                )}
              </TextAreaBottomHalfWrapper>
            </TextAreaBottomWrapper>
          </TextAreaWrapper>
        </div>
      ))}
      <VerticalSeparater />
      <NewResponseButtonWrapper>
        {isAddResponsePopupOpen && (
          <NewResponsePopup
            onOutsideClick={() => setIsAddResponsePopupOpen(false)}
          >
            <NewResponsePopupButton
              color="green2"
              onClick={() => addResponse("HUMAN SAYS")}
            >
              USER RESPONSE
            </NewResponsePopupButton>
            <NewResponsePopupButton
              color="purple1"
              onClick={() => addResponse("ASSISTANT SAYS")}
            >
              ASSISTANT RESPONSE
            </NewResponsePopupButton>
          </NewResponsePopup>
        )}
        <NewResponseButton onClick={() => setIsAddResponsePopupOpen(true)}>
          <Icon type="Add" color={colors.grey2} size={24} /> NEW RESPONSE
        </NewResponseButton>
      </NewResponseButtonWrapper>
      <NewResponseButton onClick={() => reset()}>
        <Icon type="Home" color={colors.grey2} size={24} /> Reset
      </NewResponseButton>
    </Wrapper>
  )
}

const CopyToClipboardPopup = styled(Popup)`
  right: -15px;
  bottom: 30px;
`

const Wrapper = styled.div`
  width: 900px;
  margin-right: 51px;
`

// const StyledTopicButtonsList = styled(TopicButtonsList)`
//   margin-left: 32px;
// `

const TextAreaWrapper = styled.div<{ isLastUsedArea: boolean }>`
  position: relative;
  width: 100%;

  background-color: white;
  /* box-shadow: 0px 0px 40px ${colors.shadow}; */
  border: ${({ isLastUsedArea }) =>
    isLastUsedArea ? `3px solid ${colors.green1}` : "none"};

  border-radius: 8px;
  padding: 32px 48px;
  .DraftEditor-root {
    ${applyTextStyle("label11")};
    font-size: 18px;
    color: ${colors.black2};
  }

  .public-DraftEditorPlaceholder-root {
    pointer-events: none;
    position: absolute;
    color: ${colors.grey2};
  }

  .public-DraftEditorPlaceholder-hasFocus {
    display: none;
  }
`

const TextAreaWrapperSmall = styled.div`
  position: relative;
  width: 100%;
  background-color: white;
  /* box-shadow: 0px 0px 40px ${colors.shadow}; */
  border-radius: 8px;
  padding: 12px 48px;
`

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TextAreaTitle = styled.div`
  ${applyTextStyle("label1")};
  color: ${colors.grey6};
  cursor: pointer;
`

const TextAreaTopRightWrapper = styled.div`
  display: flex;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
`

const TextAreaBottomWrapper = styled.div`
  display: flex;
  margin-top: 35px;
  justify-content: space-between;
`

const TextAreaBottomHalfWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

// const FormatOptions = styled.div`
//   width: 36px;
//   height: 36px;
//   border: 1px solid ${colors.grey1};
//   padding: 4px;
//   border-radius: 4px;
//   cursor: pointer;
//   margin-right: 31px;
// `

const TextAreaBottomText = styled.div<{ marginRight?: number }>`
  ${applyTextStyle("label1")};
  color: ${colors.grey2};
  margin-right: ${({ marginRight }) => marginRight}px;
`

const VerticalLine = styled.div`
  width: 1px;
  height: 24px;
  background: ${colors.lightBlue};
  margin-right: 17px;
`

const SplitScreenLink = styled(Link)`
  margin-right: 18px;
`

const NewResponseButtonWrapper = styled.div`
  position: relative;
`

const NewResponsePopup = styled(Popup)`
  gap: 10px;
  left: 50%;
  transform: translate(-50%, 0);
  top: -13px;
`

const NewResponsePopupButton = styled.button<{ color: Color }>`
  ${applyTextStyle("label4")};
  padding: 16px;
  outline: none;
  border: 1px solid ${colors.grey1};
  background-color: ${colors.grey9};
  color: ${({ color }) => colors[color]};
  border-radius: 8px;
  cursor: pointer;
`

const NewResponseButton = styled.button`
  ${applyTextStyle("label4")};
  width: 100%;
  padding: 16px 0 15px 0;
  border: 1px solid ${colors.grey1};
  color: ${colors.grey2};
  border-radius: 8px;
  background-color: ${colors.grey9};
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
  margin-bottom: 30px;
`

const VerticalSeparater = styled.div`
  height: 16px;
  width: 1px;
  margin: 0 auto;
  background-color: ${colors.grey1};
`

const StyledCloseAreaIcon = styled(Icon)`
  position: absolute;
  top: 21px;
  right: 21px;
  cursor: pointer;
`

const ResponseText = styled.div<{ color: Color }>`
  ${applyTextStyle("label5")};
  margin-bottom: 13px;
  color: ${({ color }) => colors[color]};
`

export default ComplexTextarea
