import React from "react"
import styled from "styled-components"
import colors from "../../../styles/colors"
import { applyTextStyle } from "../../../styles/typography"
import Icon from "../../icon/Icon"

export interface IProps {
  className?: string
  children: React.ReactNode
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

const Dropdown: React.FC<IProps> = ({ className, onChange, children }) => {
  return (
    <DropdownWrapper className={className}>
      <DropdownInnerWrapper>
        <StyledIconDropdown
          type="DropUp"
          color={colors.grey2}
          size={24}
        />
        <StyledSelect onChange={e => (onChange ? onChange(e) : undefined)}>
          {children}
        </StyledSelect>
      </DropdownInnerWrapper>
    </DropdownWrapper>
  )
}

const DropdownWrapper = styled.div<{ marginBottom?: number }>`
  position: relative;
  display: block;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : 0)}px;
`

const DropdownInnerWrapper = styled.div`
  position: relative;
`

const StyledSelect = styled.select`
  display: block;
  box-sizing: border-box;
  background-color: ${colors.neutralWhite};
  outline: 0;
  border: 1px solid ${colors.grey1};
  color: ${colors.grey2};
  appearance: none;
  border-radius: 4px;
  ${applyTextStyle("label1")};
  padding: 8px 36px 7px 12px;
`

const StyledIconDropdown = styled(Icon)`
  position: absolute;
  right: 8px;
  top: 6px;
  pointer-events: none;
  transform: rotate(180deg);
`

export default Dropdown
