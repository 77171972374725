import React from "react"
import styled from "styled-components"
import colors from "../../../styles/colors"
import { applyTextStyle } from "../../../styles/typography"
export interface IProps {
  className?: string
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  autoFocus?: boolean
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void
}

const Input: React.FC<IProps> = ({
  className,
  onChange,
  value,
  autoFocus,
  onBlur,
}) => {
  return (
    <InputWrapper className={className}>
      <InputInnerWrapper>
        <StyledInput
          autoFocus={autoFocus}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      </InputInnerWrapper>
    </InputWrapper>
  )
}

const InputWrapper = styled.div`
  position: relative;
  display: block;
`

const InputInnerWrapper = styled.div`
  position: relative;
`

const StyledInput = styled.input`
  display: block;
  box-sizing: border-box;
  background-color: ${colors.neutralWhite};
  outline: 0;
  border: 1px solid ${colors.grey1};
  color: ${colors.grey2};
  appearance: none;
  border-radius: 4px;
  ${applyTextStyle("label1")};
  padding: 8px 12px 7px 12px;
`

export default Input
