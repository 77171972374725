import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import colors from "../styles/colors"
import { applyTextStyle } from "../styles/typography"

interface IProps {
  className?: string
  children: React.ReactNode
  onOutsideClick?: () => void
}

const Popup: React.FC<IProps> = ({ children, className, onOutsideClick }) => {
  const ref: any = useRef(null)
  useEffect(() => {
    const handler = (event: MouseEvent) => {
      if (
        ref &&
        ref.current &&
        !ref.current.contains(event.target) &&
        onOutsideClick
      ) {
        onOutsideClick()
      }
    }
    document.addEventListener("mousedown", handler)
    return () => {
      document.removeEventListener("mousedown", handler)
    }
  }, [])
  return (
    <Wrapper ref={ref} className={className}>
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  ${applyTextStyle('label9')};
  background-color: ${colors.grey1};
  display: flex;
  align-items: center;
  position: absolute;
  border-radius: 15px;
  justify-content: center;
  padding: 16px;
  z-index: 500;
  color: ${colors.black};
`

export default Popup
