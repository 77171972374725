import React from "react"
import styled from "styled-components"
import {
  IconAdd,
  IconAddCircle,
  IconAddCircleOutline,
  IconAddReaction,
  IconArrowRight,
  IconArticle,
  IconBook,
  IconChat,
  IconChatBubbleOutline,
  IconChevronRight,
  IconCloseOutline,
  IconCommandKey,
  IconContentCopy,
  IconDelete,
  IconDescription,
  IconDropUp,
  IconFolder,
  IconFormatBold,
  IconFormatClear,
  IconFormatItalic,
  IconFormatListBulleted,
  IconFormatListNumbered,
  IconFormatUnderlined,
  IconHelpOutline,
  IconHome,
  IconInsertLink,
  IconList,
  IconMessage,
  IconMore,
  IconPdf,
  IconSplitScreen,
  IconTextSnippet,
  IconTopic,
} from "."
import { IconType } from "./IconType"

interface IProps {
  type: IconType
  size?: number
  color?: string
  className?: string
  onClick?: () => void
}

interface IconProps extends IProps {
  color: string
  size: number
}

const Icon = (props: IProps) => {
  const size = props.size !== undefined ? props.size : 13
  const color = props.color ? props.color : "black"
  return (
    <IconWrapper
      className={props.className}
      onClick={props.onClick}
      size={size}
    >
      {props.type === "Add" && <IconAdd size={size} color={color} />}
      {props.type === "AddCircle" && (
        <IconAddCircle size={size} color={color} />
      )}
      {props.type === "AddCircleOutline" && (
        <IconAddCircleOutline size={size} color={color} />
      )}
      {props.type === "AddReaction" && (
        <IconAddReaction size={size} color={color} />
      )}
      {props.type === "DropUp" && <IconDropUp size={size} color={color} />}
      {props.type === "Article" && <IconArticle size={size} color={color} />}
      {props.type === "Book" && <IconBook size={size} color={color} />}
      {props.type === "Chat" && <IconChat size={size} color={color} />}
      {props.type === "ChatBubbleOutline" && (
        <IconChatBubbleOutline size={size} color={color} />
      )}
      {props.type === "ChevronRight" && (
        <IconChevronRight size={size} color={color} />
      )}
      {props.type === "ContentCopy" && (
        <IconContentCopy size={size} color={color} />
      )}
      {props.type === "Delete" && <IconDelete size={size} color={color} />}
      {props.type === "Description" && (
        <IconDescription size={size} color={color} />
      )}
      {props.type === "ArrowRight" && (
        <IconArrowRight size={size} color={color} />
      )}
      {props.type === "Folder" && <IconFolder size={size} color={color} />}
      {props.type === "FormatBold" && (
        <IconFormatBold size={size} color={color} />
      )}
      {props.type === "FormatClear" && (
        <IconFormatClear size={size} color={color} />
      )}
      {props.type === "FormatItalic" && (
        <IconFormatItalic size={size} color={color} />
      )}
      {props.type === "FormatListBulleted" && (
        <IconFormatListBulleted size={size} color={color} />
      )}
      {props.type === "FormatListNumbered" && (
        <IconFormatListNumbered size={size} color={color} />
      )}
      {props.type === "FormatUnderlined" && (
        <IconFormatUnderlined size={size} color={color} />
      )}
      {props.type === "HelpOutline" && (
        <IconHelpOutline size={size} color={color} />
      )}
      {props.type === "CloseOutline" && (
        <IconCloseOutline size={size} color={color} />
      )}
      {props.type === "Home" && <IconHome size={size} color={color} />}
      {props.type === "InsertLink" && (
        <IconInsertLink size={size} color={color} />
      )}
      {props.type === "CommandKey" && (
        <IconCommandKey size={size} color={color} />
      )}
      {props.type === "List" && <IconList size={size} color={color} />}
      {props.type === "Message" && <IconMessage size={size} color={color} />}
      {props.type === "More" && <IconMore size={size} color={color} />}
      {props.type === "SplitScreen" && (
        <IconSplitScreen size={size} color={color} />
      )}
      {props.type === "TextSnippet" && (
        <IconTextSnippet size={size} color={color} />
      )}
      {props.type === "Topic" && <IconTopic size={size} color={color} />}
      {props.type === "PDF" && <IconPdf size={size} color={color} />}
    </IconWrapper>
  )
}

export const IconWrapper = styled.span<Pick<IconProps, "size">>`
  position: relative;
  display: inline-block;
  width: ${props => props.size}px;
  height: ${props => props.size}px;

  &:last-child {
    margin-right: 0;
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;

    path {
      transition: fill 0.3s ease;
    }
  }
`

export default Icon
