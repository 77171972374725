import { textStyles } from '../../styles/typography'

/**
 * Define the possible values for CSS 'font-weight' property and give them
 * names
 */
export enum FontWeight {
  Regular = 400,
  SemiBold = 600,
  Bold = 700,
}

/**
 * Define CSS rules that generally apply to text elements
 */
export interface ITextRules {
  fontSize: number // In px
  lineHeight: number // In px
  fontFamily?: string
  fontWeight?: FontWeight
  textTransform?: TextTransformType
}

export type TextStyleType = keyof typeof textStyles

export type TextTagType =
  | 'div'
  | 'p'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'strong'
  | 'em'
  | 'span'

/**
 * Define the possible values for the CSS 'text-transform' property
 */
 export enum TextTransformType {
  Lowercase = 'lowercase',
  Uppercase = 'uppercase',
  Capitalize = 'capitalize',
}

export interface ITextStyleConfig {
  [name: string]: ITextRules
}
