import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import ComplexTextarea from "../components/pages/dashboard/ComplexTextarea"
import Assistant from "../components/pages/dashboard/Assistant"
import useAuthenticationCheck from "../hooks/useAuthenticationCheck"
import { useUser } from "../context/UserContext" // adjust the import path as needed
import { useToken } from "../context/UseToken"

// type Category = {
//   id: number
//   name: string
//   color: string
//   description: string
// }

const DashboardPage: React.FC = () => {
  console.log("Dashboard mounting")

  useAuthenticationCheck()

  const [suggestions, setSuggestions] = useState<ValidateResponse>()
  const [openMessages, setOpenMessages] = useState<boolean[]>([])

  // categories not currently being used
  // const { user } = useUser()
  // const { getToken } = useToken()
  // const [categories, setCategories] = useState<Category[]>([])

  // useEffect(() => {
  //   if (user) {
  //     fetchCategories()
  //   }
  // }, [user])

  // const fetchCategories = async () => {
  //   try {
  //     const token = await getToken()
  //     const categories = await fetch(
  //       `${process.env.GATSBY_API_URL}/v1/rules/categories/default`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     )
  //     const defaultCategories = await categories.json()

  //     setCategories(defaultCategories)
  //   } catch (error) {
  //     console.error("Failed to fetch categories:", error)
  //   }
  // }

  return (
    <>
      <Helmet>
        <title>Codaco Dashboard</title>
      </Helmet>
      <StyledComplexTextarea
        onValidate={suggestions => setSuggestions(suggestions)}
        openMessages={openMessages}
      />
      <Assistant onMessageClick={setOpenMessages} suggestions={suggestions} />
    </>
  )
}

const StyledComplexTextarea = styled(ComplexTextarea)`
  margin-top: 50px;
`

export default DashboardPage
