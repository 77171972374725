import React, { useEffect, useState } from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import { applyTextEllipsis, applyTextStyle } from "../../styles/typography"
import Link from "../actions/Link"
import Icon from "../icon/Icon"

interface IProps {
  messages: any[]
  onMessageClick: (openMessages: boolean[]) => void
}

const SuggestionsList: React.FC<{ messages: any[] }> = ({ messages }) => {
  const [openMessages, setOpenMessages] = useState<boolean[]>(
    messages.map(() => false)
  );

  useEffect(() => {
    setOpenMessages(messages.map(() => false));
  }, [messages]);

  return (
    <div>
      {messages.map((message, index) => (
        <MessageWrapper key={message.rule.id}>
          <InnerMessageWrapper
            isOpen={openMessages[index]}
            onClick={() => {
              const openMessagesToPass = openMessages.map((isOpen, i) =>
                index === i ? !isOpen : isOpen
              );
              setOpenMessages(openMessagesToPass);
            }}
          >
            <RowContainer>
              <Dot backgroundColor={message.rule.category.color} />

              <ShortDescriptionText isOpen={openMessages[index]}>
                {message.rule.shortDescription}
              </ShortDescriptionText>
              {message.words.length > 1 && (
                <SuggestionsCounter>
                  <Count>{message.words.length}</Count>
                </SuggestionsCounter>
              )}
            </RowContainer>




            {openMessages[index] && (
              <div>
                {/* Render words without possible replacements in a row */}
                <TopText isOpen={openMessages[index]} hasOrigin={true} hasReplacements={false}>
                  {message.words
                    .filter((word) => word.origin && word.possibleReplacements.length === 0)
                    .map((word, wordIndex) => (
                      <React.Fragment key={word.origin + wordIndex}>
                        <OriginText>{word.origin}</OriginText>
                        <Space />
                      </React.Fragment>
                    ))}
                </TopText>

                {/* Render words with possible replacements in a column */}
                {message.words
                  .filter((word) => word.possibleReplacements.length > 0)
                  .map((word, wordIndex) => (
                    <div key={word.origin + wordIndex}>
                      <TopText isOpen={openMessages[index]} hasOrigin={word.origin.length > 0} hasReplacements={true}>
                        <OriginText>{word.origin}</OriginText>

                        <ArrowRightIcon type="ArrowRight" size={24} color={colors.grey2} />
                        <PossibleReplacements>
                          {word.possibleReplacements.map((item) => (
                            <PossibleReplacementBox key={item}>{item}</PossibleReplacementBox>
                          ))}
                        </PossibleReplacements>
                      </TopText>
                    </div>
                  ))}
              </div>
            )}


            {openMessages[index] && (
              <Description>{message.rule.description}</Description>
            )}
          </InnerMessageWrapper>
          {openMessages[index] && (
            <MessageBottom>
              <Link caption="Add to dictionary" iconType="Book"></Link>
              <MessageBottomRight>
                <DeleteIcon type="Delete" size={24} color={colors.grey2} />
                <MoreIcon type="More" size={24} color={colors.grey2} />
              </MessageBottomRight>
            </MessageBottom>
          )}
        </MessageWrapper>
      ))}
    </div>
  );

};

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;  // This provides some space between the items
`;

const SuggestionsCounter = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
`

const Count = styled.div`
  ${applyTextStyle("label5")};
  width: 24px;
  height: 24px;
  background: ${colors.grey4};
  text-align: center;
  border-radius: 50%;
  color: ${colors.neutralWhite};
  padding: 2.5px 0 2.5px 0;
`

const MessageWrapper = styled.div`
  position: relative;
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
  box-sizing: border-box;
  border: 1px solid ${colors.grey7};
  padding: 12px 40px;
  border-radius: 8px;
`

// const MessageType = styled.div`
//   position: absolute;
//   right: 20px;
//   top: 15px;
//   ${applyTextStyle("label10")};
//   color: ${colors.grey2};
// `

const InnerMessageWrapper = styled.div<{ isOpen: boolean }>`
  cursor: pointer;
  padding-bottom: ${({ isOpen }) => (isOpen ? "26" : "0")}px;
`

const Dot = styled.div<{ backgroundColor: string }>`
  border-radius: 50%;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 20px;
  left: 16px;
  background: ${({ backgroundColor }) => backgroundColor};
`

const TopText = styled.div<{ isOpen: boolean; hasOrigin: boolean; hasReplacements?: boolean }>`
  ${applyTextEllipsis()};
  ${applyTextStyle("label6")};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 4px;
  padding-top: 4px;
`;

const OriginText = styled.div`
  color: ${colors.black2};
  border: 1px solid ${colors.grey7};
  padding: 4px 8px;
  border-radius: 8px;
  /* margin-right: 10px; */
`

const Space = styled.span`
  margin-right: 5px;
`

const ArrowRightIcon = styled(Icon)`
  margin-right: 10px;
`

const PossibleReplacements = styled.div`
  display: flex;
  gap: 10px;
`

const PossibleReplacementBox = styled.div`
  background-color: ${colors.grey8};
  border-radius: 4px;
  color: ${colors.black2};
  padding: 8px 12px;
`

const ShortDescriptionText = styled.div<{ isOpen: boolean }>`
  color: ${props => (props.isOpen ? colors.black : colors.grey2)};
`;

const Description = styled.div`
  ${applyTextStyle("label9")};
  color: ${colors.grey6};
`

const MessageBottom = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
`

const MessageBottomRight = styled.div`
  display: flex;
`

const DeleteIcon = styled(Icon)`
  margin-right: 8px;
  cursor: pointer;
`

const MoreIcon = styled(Icon)`
  cursor: pointer;
`

export default SuggestionsList
