import React from "react"
import styled, { css } from "styled-components"
import colors from "../../styles/colors"
import { applyTextStyle } from "../../styles/typography"
import Icon from "../icon/Icon"

interface IProps {
  className?: string
  caption: string
  isArrowLeft?: boolean
  onClick?: (event?: React.MouseEvent) => void
}

const SidebarButton: React.FC<IProps> = ({
  className,
  caption,
  onClick,
  isArrowLeft,
}) => {
  return (
    <StyledButton onClick={onClick} className={className}>
      <StyledIcon
        isArrowLeft={isArrowLeft || false}
        type="ChevronRight"
        color={colors.grey6}
        size={24}
      />
      <Label>{caption}</Label>
    </StyledButton>
  )
}

const StyledButton = styled.button`
  border-radius: 4px;
  background: ${colors.transparent};
  border: 1px solid ${colors.grey1};
  color: ${colors.grey6};
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  padding: 3px 12px 3px 6px;
`

const Label = styled.label`
  margin-left: 4px;
  ${applyTextStyle("label4")};
  cursor: pointer;
`

const StyledIcon = styled(Icon)<{ isArrowLeft: boolean }>`
  ${({ isArrowLeft }) =>
    isArrowLeft &&
    css`
      transform: rotate(180deg);
    `}
`

export default SidebarButton
