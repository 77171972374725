import { css } from 'styled-components'
import {
  ITextRules,
  TextStyleType,
  ITextStyleConfig,
  FontWeight,
  TextTransformType,
} from '../models/typography'

/**
 * Configure the text style presets
 */
export const textStyles: ITextStyleConfig = {
  heading1: {
    fontSize: 72,
    lineHeight: 98,
    fontWeight: FontWeight.Bold,
  },
  label1: {
    fontSize: 14,
    lineHeight: 19,
    fontWeight: FontWeight.SemiBold,
  },
  label2: {
    fontSize: 18,
    lineHeight: 24,
    fontWeight: FontWeight.Bold,
  },
  label3: {
    fontSize: 21,
    lineHeight: 40,
    fontWeight: FontWeight.Regular,
  },
  label4: {
    fontSize: 12,
    lineHeight: 17,
    fontWeight: FontWeight.Bold,
    textTransform: TextTransformType.Uppercase
  },
  label5: {
    fontSize: 14,
    lineHeight: 19,
    fontWeight: FontWeight.Bold,
  },
  label6: {
    fontSize: 18,
    lineHeight: 24,
    fontWeight: FontWeight.Regular,
  },
  label7: {
    fontSize: 18,
    lineHeight: 24,
    fontWeight: FontWeight.Bold,
  },
  label8: {
    fontSize: 14,
    lineHeight: 19,
    fontWeight: FontWeight.Regular,
  },
  label9: {
    fontSize: 16,
    lineHeight: 20,
    fontWeight: FontWeight.Regular
  },
  label10: {
    fontSize: 12,
    lineHeight: 17,
    fontWeight: FontWeight.Regular,
    textTransform: TextTransformType.Uppercase
  },
  label11: {
    fontSize: 21,
    lineHeight: 40,
    fontWeight: FontWeight.Regular,
  },
}

export const defaultTextStyle: TextStyleType = 'label1'

/**
 * Create the needed CSS for a given text style (works like a mixin)
 * @param rules The ITextRules object, containing all needed properties for a text
 */
const applyCustomTextStyle = (rules: ITextRules) => {
  return css`
    ${getTextRules(rules)}
  `
}

/**
 * Return a FlattenSimpleInterpolation css`` literal for given
 * text rules
 * @param rules The ITextRules object
 */
const getTextRules = (rules: ITextRules) => {
  return css`
    font-size: ${rules.fontSize}px;
    line-height: ${rules.lineHeight}px;
    ${rules.fontFamily !== undefined &&
    css`
      font-family: '${rules.fontFamily}';
    `}
    ${rules.fontWeight !== undefined &&
    css`
      font-weight: ${rules.fontWeight};
    `}
    ${rules.textTransform !== undefined &&
    css`
      text-transform: ${rules.textTransform};
    `}
  `
}

/**
 * Apply a predefined text style (works like applyCustomTextStyle, but
 * takes as input a preset name, instead of an ITextRules object)
 * @param name The text style key, eg. 'caption' or 'labelHeavy'
 */
const applyTextStyle = (name: TextStyleType) => {
  const textStyle = textStyles[name]
  return applyCustomTextStyle(textStyle)
}

/**
 * Apply ellipsis-style text overflow
 */
const applyTextEllipsis = () => {
  return css`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `
}

/**
 * Remove text ellipsis style (unset certain properties)
 */
const removeTextEllipsis = () => {
  return css`
    text-overflow: unset;
    white-space: unset;
    overflow: unset;
  `
}

export {
  applyCustomTextStyle,
  applyTextStyle,
  applyTextEllipsis,
  removeTextEllipsis,
}
