import React from "react"
import { getMessageColor } from "../helpers/getMessageColor.helper"

// unable to pass custom props to Tooltip so use className
const ToolTip: React.FC = (props: any) => {
  return (
    <mark
      style={{
        backgroundColor: props.className.withBackground
          ? getMessageColor(props.className.category, true)
          : "transparent",
        WebkitTextDecorationColor: getMessageColor(props.className.category),
        textDecorationColor: getMessageColor(props.className.category),
        textDecorationLine: "underline",
        textUnderlineOffset: "5px",
        textDecorationThickness: "2px",
      }}
    >
      {props.children}
    </mark>
  )
}

export default ToolTip
