import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import { applyTextStyle } from "../../styles/typography"
import Icon from "../icon/Icon"
import { IconType } from "../icon/IconType"

interface IProps {
  className?: string
  iconType: IconType
  caption: string
  onClick?: (event?: React.MouseEvent) => void
}

const Link: React.FC<IProps> = ({ className, iconType, caption, onClick }) => {
  return (
    <Wrapper className={className} onClick={onClick}>
      <StyledIcon type={iconType} size={24} color={colors.grey2} />
      <Text>{caption}</Text>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const StyledIcon = styled(Icon)`
  margin-right: 8px;
`

const Text = styled.div<{ marginRight?: number }>`
  ${applyTextStyle("label1")};
  color: ${colors.grey2};
`

export default Link
