import React, { useState } from "react"
import styled, { css } from "styled-components"
import colors from "../../../styles/colors"
import { applyTextStyle } from "../../../styles/typography"
import SidebarButton from "../../actions/SidebarButton"
import Icon from "../../icon/Icon"
import SuggestionsList from "../../lists/SuggestionsList"
import ScoreProgressBar from "../../ScoreProgressBar"

interface IProps {
  suggestions?: any
  onMessageClick: (openMessages: boolean[]) => void
}

const Assistant: React.FC<IProps> = ({ suggestions, onMessageClick }) => {
  const showMessages = suggestions && suggestions.messages.length > 0
  const [isOpen, setIsOpen] = useState<boolean>(true)
  const [isSticky, setIsSticky] = useState<boolean>(true)

  return (
    <>
      {/* {!isOpen && (
        <StyledSidebarShowButton
          isArrowLeft
          onClick={() => {
            setIsOpen(true)
            setTimeout(() => {
              setIsSticky(true)
            }, 500)
          }}
          caption="SHOW ASSISTANT"
        />
      )} */}
      <Wrapper isSticky={isSticky} isOpen={isOpen}>
        {isOpen && (
          <>
            {/* <StyledSidebarButton
              onClick={() => {
                setIsSticky(false)
                setIsOpen(false)
              }}
              caption="HIDE ASSISTANT"
            /> */}
            <SuggestionsCounter>
              {showMessages && (
                <Count>
                  {suggestions.messages.reduce(
                    (acc, message) => acc + message.words.length,
                    0
                  )}
                </Count>
              )}
              <CountText>Suggestions</CountText>
            </SuggestionsCounter>
            {showMessages && (
              <SuggestionsList
                onMessageClick={onMessageClick}
                messages={suggestions.messages}
              />
            )}
            {!showMessages && (
              <NoMessagesContainer>
                <NoMessagesInnerContainer>
                  <NoMessagesIcon
                    type="AddReaction"
                    size={80}
                    color={colors.green2}
                  />
                  <NoMessagesFirstText>
                    Nothing to check yet
                  </NoMessagesFirstText>
                  <NoMessagesSecondText>
                    Start writing or upload a document to see Codaco's feedback.
                  </NoMessagesSecondText>
                </NoMessagesInnerContainer>
              </NoMessagesContainer>
            )}
            <ScoreContainer>
              <div>
                <Score>{suggestions?.scores?.overallScore || 0}</Score>
                <OverallText>Overall score</OverallText>
              </div>
              <ScoreRight>
                {suggestions?.scores?.scoresArray?.map(scoreItem => (
                  <ScoreProgressBar
                    key={scoreItem.name}
                    title={scoreItem.name}
                    defaultColor={scoreItem.color} // Use hex string directly
                    fillColor={scoreItem.color} // Use hex string directly
                    progress={scoreItem.score}
                  />
                ))}
              </ScoreRight>
            </ScoreContainer>
          </>
        )}
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div<{ isOpen: boolean; isSticky: boolean }>`
  width: 0px;
  height: 100 ${({ isOpen, isSticky }) => (isOpen && isSticky ? "vh" : "%")};
  background: ${colors.neutralWhite};
  display: flex;
  flex-direction: column;
  position: ${({ isSticky }) => (isSticky ? "sticky" : "absolute")};
  top: 0px;
  right: 0px;
  overflow: hidden;
  overflow-y: auto;
  transition: 0.5s;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      padding: 32px 32px 40px 32px;
      box-shadow: 0px 0px 40px ${colors.shadow};
      width: 540px;
    `}
`

const StyledSidebarButton = styled(SidebarButton)`
  width: 143px;
  align-self: end;
`

const StyledSidebarShowButton = styled(SidebarButton)`
  width: 151px;
  height: 32px;
  position: sticky;
  margin-left: 361px;
  top: 32px;
  margin-top: 32px;
`

const SuggestionsCounter = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
  margin-bottom: 13px;
  margin-top: 20px;
`

const Count = styled.div`
  ${applyTextStyle("label5")};
  width: 24px;
  height: 24px;
  background: ${colors.grey4};
  text-align: center;
  border-radius: 50%;
  color: ${colors.neutralWhite};
  padding: 2.5px 0 2.5px 0;
`

const CountText = styled.div`
  ${applyTextStyle("label1")};
  color: ${colors.grey4};
`

const NoMessagesContainer = styled.div`
  border-radius: 8px;
  border: 1px solid ${colors.grey4};
  padding: 130px 0;
`

const NoMessagesInnerContainer = styled.div`
  width: 50%;
  margin: auto;
`

const NoMessagesIcon = styled(Icon)`
  display: block;
  margin: 0 auto;
`

const NoMessagesFirstText = styled.div`
  ${applyTextStyle("label7")};
  color: ${colors.grey6};
  margin-bottom: 4px;
  text-align: center;
`

const NoMessagesSecondText = styled.div`
  ${applyTextStyle("label6")};
  color: ${colors.grey2};
  text-align: center;
`

const ScoreContainer = styled.div`
  display: flex;
  margin-top: 100px;
  justify-content: space-between;
`

const Score = styled.div`
  ${applyTextStyle("heading1")};
  color: ${colors.grey6};
`

const OverallText = styled.div`
  ${applyTextStyle("label7")};
  color: ${colors.grey6};
  margin-bottom: 4px;
`

const ScoreRight = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 282px;
  margin-top: 60px;
  gap: 20px;
`

export default Assistant
