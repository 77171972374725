import React, { useState } from "react"
import styled from "styled-components"
import { languages } from "../models/languages"
import colors from "../styles/colors"
import Input from "./forms/controls/Input"
import Dropdown from "./forms/controls/Dropdown"

import { applyTextStyle } from "../styles/typography"

interface IProps {
  title: string
  setTitle: any
  language: string
  setLanguage: any
}

const IntentTitle: React.FC<IProps> = ({
  title,
  setTitle,
  language,
  setLanguage,
}) => {
  const [isTitleInputOpen, setIsTitleInputOpen] = useState<boolean>(false)
  return (
    <TextAreaWrapperSmall>
      <TopWrapper>
        {isTitleInputOpen ? (
          <form
            onSubmit={e => {
              e.preventDefault()
              setIsTitleInputOpen(false)
            }}
          >
            <Input
              autoFocus
              onChange={e => setTitle(e.target.value)}
              value={title}
              onBlur={() => setIsTitleInputOpen(false)}
            ></Input>
          </form>
        ) : (
          <TextAreaTitle onClick={() => setIsTitleInputOpen(true)}>
            {title || "Type a well thought intent name here"}
          </TextAreaTitle>
        )}
        <TextAreaTopRightWrapper>
          {/* <Switch
              label="Example intent"
              onChange={e => console.log(e.target.checked)}
            ></Switch> */}
          <Dropdown onChange={e => setLanguage(e.target.value as Language)}>
            {languages.map(lang => (
              <option key={lang}>{lang}</option>
            ))}
          </Dropdown>
        </TextAreaTopRightWrapper>
      </TopWrapper>
    </TextAreaWrapperSmall>
  )
}

export default IntentTitle

const TextAreaWrapperSmall = styled.div`
  position: relative;
  width: 100%;
  background-color: white;
  /* box-shadow: 0px 0px 40px ${colors.shadow}; */
  border-radius: 8px;
  padding: 12px 48px;
`

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const TextAreaTitle = styled.div`
  ${applyTextStyle("label1")};
  color: ${colors.grey6};
  cursor: pointer;
`
const TextAreaTopRightWrapper = styled.div`
  display: flex;
  gap: 15px;
  justify-content: space-between;
  align-items: center;
`
